import { FormInstance } from "antd/es/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";

declare let window: any;

interface IMarketing_Dashboard_With_Left_Menu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMarketing_Dashboard_With_Left_Menu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class Marketing_Dashboard_With_Left_Menu_ScreenBase extends React.PureComponent<
  IMarketing_Dashboard_With_Left_Menu_ScreenProps,
  any
> {
  marketing_dashboard_with_left_menu_802981_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c6d01134-5d8a-47ef-99a3-9ad366ccb75b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 322132, PropertyName: "value", Value: "Dashboard" },
        { Id: 416225, PropertyName: "value", Value: "Managment" },
        { Id: 412311, PropertyName: "value", Value: "Document" },
        { Id: 987100, PropertyName: "value", Value: "Contact" },
        { Id: 749677, PropertyName: "value", Value: "Prospect" },
        { Id: 423174, PropertyName: "value", Value: "Workflow" },
        { Id: 785082, PropertyName: "value", Value: "Connection" },
        { Id: 760390, PropertyName: "value", Value: "Chat Integration" },
        { Id: 417470, PropertyName: "value", Value: "Marketing" },
        { Id: 386132, PropertyName: "value", Value: "Email Integration" },
        { Id: 56597, PropertyName: "value", Value: "Customer" },
        { Id: 863361, PropertyName: "value", Value: "Transaction" },
        { Id: 681318, PropertyName: "value", Value: "Maintenance" },
        { Id: 4344, PropertyName: "value", Value: "Overview" },
        { Id: 639557, PropertyName: "label", Value: "Administrator" },
        { Id: 715169, PropertyName: "label", Value: "My profile" },
        { Id: 878839, PropertyName: "label", Value: "Settings" },
        { Id: 31510, PropertyName: "label", Value: "Logout" },
        { Id: 160638, PropertyName: "value", Value: "Document" },
        { Id: 797824, PropertyName: "value", Value: "146.000" },
        { Id: 703356, PropertyName: "value", Value: "17 %" },
        { Id: 959346, PropertyName: "value", Value: "Since Last Week" },
        { Id: 152077, PropertyName: "value", Value: "Contact" },
        { Id: 401621, PropertyName: "value", Value: "1400" },
        { Id: 460685, PropertyName: "value", Value: "17 %" },
        { Id: 211461, PropertyName: "value", Value: "Since Last Week" },
        { Id: 663723, PropertyName: "value", Value: "Email" },
        { Id: 456850, PropertyName: "value", Value: "200.382" },
        { Id: 851228, PropertyName: "value", Value: "17 %" },
        { Id: 682321, PropertyName: "value", Value: "Since Last Week" },
        { Id: 218858, PropertyName: "value", Value: "Recent Workflow" },
        { Id: 701566, PropertyName: "value", Value: "17 %" },
        { Id: 312307, PropertyName: "value", Value: "Recent Marketing" },
        { Id: 244668, PropertyName: "value", Value: "17 %" },
        { Id: 837095, PropertyName: "value", Value: "Document" },
        { Id: 815245, PropertyName: "value", Value: "Document Tracking Information" },
        { Id: 625671, PropertyName: "value", Value: "Weekly" },
        { Id: 773559, PropertyName: "label", Value: "Daily" },
        { Id: 764381, PropertyName: "label", Value: "Weekly" },
        { Id: 888619, PropertyName: "label", Value: "Monthly" },
        { Id: 650058, PropertyName: "label", Value: "This year" },
        { Id: 476936, PropertyName: "value", Value: "Name" },
        { Id: 925586, PropertyName: "value", Value: "File" },
        { Id: 866524, PropertyName: "value", Value: "Category" },
        { Id: 593014, PropertyName: "value", Value: "Author" },
        { Id: 520584, PropertyName: "value", Value: "Status" },
        { Id: 802981, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 440855, PropertyName: "title", Value: "Name" },
        { Id: 983658, PropertyName: "value", Value: "Annual Report" },
        { Id: 108036, PropertyName: "value", Value: "PDF" },
        { Id: 585668, PropertyName: "value", Value: "Property" },
        { Id: 747373, PropertyName: "value", Value: "Diana Matthews" },
        { Id: 611873, PropertyName: "value", Value: "Sending" },
        { Id: 851356, PropertyName: "value", Value: "Popular Product" },
        { Id: 572538, PropertyName: "value", Value: "Gadget Converter" },
        { Id: 550462, PropertyName: "value", Value: "$ 250" },
        { Id: 343539, PropertyName: "value", Value: "Lens Camera" },
        { Id: 849442, PropertyName: "value", Value: "$ 50" },
        { Id: 459372, PropertyName: "value", Value: "Airpods" },
        { Id: 859476, PropertyName: "value", Value: "$ 100" },
        { Id: 805091, PropertyName: "value", Value: "Mackbook" },
        { Id: 318168, PropertyName: "value", Value: "$ 900" },
        { Id: 418645, PropertyName: "value", Value: "Chat" },
        { Id: 283351, PropertyName: "value", Value: "Debra Young" },
        { Id: 169586, PropertyName: "value", Value: "How's my product ?" },
        { Id: 165662, PropertyName: "value", Value: "Dorothy Collins" },
        { Id: 294619, PropertyName: "value", Value: "How was the meeting" },
        { Id: 870345, PropertyName: "value", Value: "Chris Jordan" },
        { Id: 724764, PropertyName: "value", Value: "How empoyee performancce" },
        { Id: 764891, PropertyName: "value", Value: "Denise Murphy" },
        { Id: 491642, PropertyName: "value", Value: "How was the meeting" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.marketing_dashboard_with_left_menu_802981_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("marketing_dashboard_with_left_menu", "");
      return;
    }

    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("marketing_dashboard_with_left_menu", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("marketing_dashboard_with_left_menu", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}

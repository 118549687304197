import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IResetPassword_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IResetPassword_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  PasswordReset: string;
  PasswordReset_dummy: string;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp826965Enabled: "enabled" | "disabled";
}

export class ResetPassword_ScreenBase extends React.PureComponent<IResetPassword_ScreenProps, any> {
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "fa5be208-fcb0-4484-a265-af3ab6f3eaaf",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 209555, PropertyName: "value", Value: "Reset your password ?" },
        { Id: 515838, PropertyName: "value", Value: "Enter a new password for your account." },
        { Id: 646272, PropertyName: "placeholder", Value: "Enter New Password" },
        { Id: 491266, PropertyName: "placeholder", Value: "Re-enter New Password" },
        { Id: 826965, PropertyName: "label", Value: "Change Password" },
        { Id: 676745, PropertyName: "value", Value: "Not a member yet?" },
        { Id: 625034, PropertyName: "value", Value: "Sign up" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ChangeEnabledOf: "",
      IfThenElse: false,
      PasswordReset: "",
      goToSignInActivity: "",
      NAVIGATE: "",
      isComp826965Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "");
      return;
    }

    if (true) {
      await this.ResetPasswordPageInit();
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("826965");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ResetPasswordPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ResetPasswordPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ResetPasswordComponent_646272_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "enabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ResetPasswordComponent_646272_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ResetPasswordComponent_646272_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        )
      )
    ) {
      isErrorOccurred = await this.ResetPasswordComponent_646272_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ResetPasswordComponent_646272_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ResetPasswordComponent_491266_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "enabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ResetPasswordComponent_491266_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ResetPasswordComponent_491266_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        )
      )
    ) {
      isErrorOccurred = await this.ResetPasswordComponent_491266_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ResetPasswordComponent_491266_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ResetPasswordComponent_826965_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required.",
      formName: "resetpassword_491266_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        )
      ),
      message: "The password confirmation does not match.",
      formName: "resetpassword_491266_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PasswordReset = await ReactSystemFunctions.resetPassword(
      this,
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
      ),
      false,
      null
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ResetPasswordComponent_625034_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ResetPassword",
      "Signup",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ResetPasswordPageInit();
    }
  }
}

import { FormInstance } from "antd/es/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAyarlar_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAyarlar_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GirisYapanKullanici: any[];
  GirisYapanKullanici_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  AktifSirket_N: any[];
  AktifSirket_N_dummy: any[];
  TedarikciListesiCount: any[];
  TedarikciListesiCount_dummy: any[];
  KullaniciListesi: any[];
  KullaniciListesi_dummy: any[];
  SifreUret_N: any[];
  SifreUret_N_dummy: any[];
  KullaniciKayit: number;
  KullaniciKayit_dummy: number;
  CustomCreateUser: any;
  CustomCreateUser_dummy: any;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  SetValueOf: any;
  SetValueOf_dummy: any;
  SetFocusTo: any;
  SetFocusTo_dummy: any;
  Kullanici_Sil: number;
  Kullanici_Sil_dummy: number;
  CustomDeleteUser: any;
  CustomDeleteUser_dummy: any;
  TokenVer: string;
  TokenVer_dummy: string;
  ParametreAyarlari_N: number;
  ParametreAyarlari_N_dummy: number;
  TokenLogKayit: number;
  TokenLogKayit_dummy: number;
  SunucuFasonTableTempDelete: number;
  SunucuFasonTableTempDelete_dummy: number;
  InsertFasoncuyaGonderilenListe: any;
  InsertFasoncuyaGonderilenListe_dummy: any;
  FasonVeriAktarMergeInto: number;
  FasonVeriAktarMergeInto_dummy: number;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Ayarlar_ScreenBase extends React.PureComponent<IAyarlar_ScreenProps, any> {
  ayarlar_622869_value_kuikaTableRef: React.RefObject<any>;
  ayarlar_557158_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "de7739da-2286-4186-880f-ce7ae6ca8a78",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 323002, PropertyName: "tab", Value: "Kullanıcı Tanımlama" },
        { Id: 466003, PropertyName: "value", Value: "Kullanıcı Kodu" },
        { Id: 262088, PropertyName: "value", Value: "Ad Soyad" },
        { Id: 264904, PropertyName: "value", Value: "Parola" },
        { Id: 553038, PropertyName: "placeholder", Value: "***********" },
        { Id: 845256, PropertyName: "label", Value: "Şifre Oluştur" },
        { Id: 191287, PropertyName: "label", Value: "Kaydet" },
        { Id: 622869, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 113897, PropertyName: "title", Value: "Kullanıcı Kodu" },
        { Id: 315224, PropertyName: "value", Value: "[datafield:cari_kodu]" },
        { Id: 854549, PropertyName: "title", Value: "Kullanıcı Adı" },
        { Id: 489127, PropertyName: "value", Value: "[datafield:depo_kodu]" },
        { Id: 954206, PropertyName: "title", Value: "Şifre" },
        { Id: 61258, PropertyName: "value", Value: "[datafield:sifre]" },
        { Id: 821325, PropertyName: "tab", Value: "Veri Tabanı Bağlantı Bilgileri" },
        { Id: 453226, PropertyName: "value", Value: "Dbname" },
        { Id: 601126, PropertyName: "value", Value: "Şube Kodu" },
        { Id: 698122, PropertyName: "value", Value: "Netsis User" },
        { Id: 310417, PropertyName: "value", Value: "Netsis Password" },
        { Id: 952832, PropertyName: "placeholder", Value: "***********" },
        { Id: 81910, PropertyName: "value", Value: "Nox User" },
        { Id: 256875, PropertyName: "value", Value: "Nox URL" },
        { Id: 206630, PropertyName: "label", Value: "Kaydet" },
        { Id: 458577, PropertyName: "label", Value: "Manuel Senkronize Et" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.ayarlar_622869_value_kuikaTableRef = React.createRef();
    this.ayarlar_557158_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GirisYapanKullanici: [],
      IfThenElse: false,
      AktifSirket_N: [],
      TedarikciListesiCount: [],
      KullaniciListesi: [],
      SifreUret_N: [],
      KullaniciKayit: 0,
      CustomCreateUser: "",
      TriggerEvent: "",
      SetValueOf: "",
      Notify: false,
      SetFocusTo: "",
      Kullanici_Sil: 0,
      CustomDeleteUser: "",
      TokenVer: "",
      ParametreAyarlari_N: 0,
      TokenLogKayit: 0,
      SunucuFasonTableTempDelete: 0,
      InsertFasoncuyaGonderilenListe: "",
      FasonVeriAktarMergeInto: 0,

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ayarlar", "fasontakiplayout_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.AyarlarPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("ayarlar", "fasontakiplayout_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ayarlar", "fasontakiplayout_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AyarlarPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.AyarlarPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ayarlar_161038_value: this.state.AktifSirket_N?.at?.(0)?.dbName ?? undefined,
      ayarlar_142568_value: this.state.AktifSirket_N?.at?.(0)?.subeKodu ?? undefined,
      ayarlar_579518_value: this.state.AktifSirket_N?.at?.(0)?.netsisUser ?? undefined,
      ayarlar_979676_value: this.state.AktifSirket_N?.at?.(0)?.noxUser ?? undefined,
      ayarlar_369893_value: this.state.AktifSirket_N?.at?.(0)?.noxUrl ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  AyarlarPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      CariKodu_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GirisYapanKullanici = result?.data.girisYapanKullanici;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarPageInit_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "bad0e1a8_4308_ffec_6c39_b4e74226342f_notify",
        this.defaultML,
        "Yetkisiz Giriş !!"
      ),
      "danger",
      "bottom-right",
      4,
      "",
      "",
      0
    );
    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Ayarlar",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AyarlarPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0
            ? stateVars.GirisYapanKullanici[0].tip
            : this.state.GirisYapanKullanici?.length > 0
            ? this.state.GirisYapanKullanici[0].tip
            : null
        ),
        false
      )
    ) {
      isErrorOccurred = await this.AyarlarPageInit_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AyarlarPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0
            ? stateVars.GirisYapanKullanici[0].tip
            : this.state.GirisYapanKullanici?.length > 0
            ? this.state.GirisYapanKullanici[0].tip
            : null
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarPageInit2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.AktifSirket_N = result?.data.aktifSirket_N;
      formVars.ayarlar_161038_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].dbName
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].dbName
          : null
      );
      formVars.ayarlar_142568_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].subeKodu
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].subeKodu
          : null
      );
      formVars.ayarlar_579518_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].netsisUser
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].netsisUser
          : null
      );
      formVars.ayarlar_979676_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].noxUser
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].noxUser
          : null
      );
      formVars.ayarlar_369893_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].noxUrl
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].noxUrl
          : null
      );
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      tip_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      Kullanici_Tipi_1: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarPageInit3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TedarikciListesiCount = result?.data.tedarikciListesiCount;
    stateVars.KullaniciListesi = result?.data.kullaniciListesi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_622869 = this.state.KullaniciListesi;
    formVars.ayarlar_557158_total = ReactSystemFunctions.value(
      this,
      stateVars.TedarikciListesiCount?.length > 0
        ? stateVars.TedarikciListesiCount[0].toplamKayitSayisi
        : this.state.TedarikciListesiCount?.length > 0
        ? this.state.TedarikciListesiCount[0].toplamKayitSayisi
        : null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarPageInit5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AyarlarPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0
            ? stateVars.GirisYapanKullanici[0].tip
            : this.state.GirisYapanKullanici?.length > 0
            ? this.state.GirisYapanKullanici[0].tip
            : null
        ),
        true
      )
    ) {
      formVars.ayarlar_161038_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].dbName
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].dbName
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AyarlarPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0
            ? stateVars.GirisYapanKullanici[0].tip
            : this.state.GirisYapanKullanici?.length > 0
            ? this.state.GirisYapanKullanici[0].tip
            : null
        ),
        true
      )
    ) {
      formVars.ayarlar_142568_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].subeKodu
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].subeKodu
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AyarlarPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0
            ? stateVars.GirisYapanKullanici[0].tip
            : this.state.GirisYapanKullanici?.length > 0
            ? this.state.GirisYapanKullanici[0].tip
            : null
        ),
        true
      )
    ) {
      formVars.ayarlar_579518_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].netsisUser
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].netsisUser
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AyarlarPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0
            ? stateVars.GirisYapanKullanici[0].tip
            : this.state.GirisYapanKullanici?.length > 0
            ? this.state.GirisYapanKullanici[0].tip
            : null
        ),
        true
      )
    ) {
      formVars.ayarlar_952832_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].netsisPass
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].netsisPass
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AyarlarPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0
            ? stateVars.GirisYapanKullanici[0].tip
            : this.state.GirisYapanKullanici?.length > 0
            ? this.state.GirisYapanKullanici[0].tip
            : null
        ),
        true
      )
    ) {
      formVars.ayarlar_979676_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].noxUser
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].noxUser
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AyarlarPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0
            ? stateVars.GirisYapanKullanici[0].tip
            : this.state.GirisYapanKullanici?.length > 0
            ? this.state.GirisYapanKullanici[0].tip
            : null
        ),
        true
      )
    ) {
      formVars.ayarlar_369893_value = ReactSystemFunctions.toString(
        this,
        stateVars.AktifSirket_N?.length > 0
          ? stateVars.AktifSirket_N[0].noxUrl
          : this.state.AktifSirket_N?.length > 0
          ? this.state.AktifSirket_N[0].noxUrl
          : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AyarlarComponent_845256_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_845256_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SifreUret_N = result?.data.sifreUret_N;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AyarlarComponent_191287_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_397591_value", "value", "", "", "")
        ),
        null
      ),
      message: "Kullanıcı Kodu Boş Bırakılamaz",
      formName: "ayarlar_397591_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_961747_value", "value", "", "", "")
        ),
        null
      ),
      message: "Ad Soyad Boş Bırakılamaz",
      formName: "ayarlar_961747_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_553038_value", "value", "", "", "")
        ),
        null
      ),
      message: "Parola Boş Bırakılamaz",
      formName: "ayarlar_553038_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CARI_KODU_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_397591_value", "value", "", "", "")),
        "string"
      ),
      DEPO_KODU_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_961747_value", "value", "", "", "")),
        "string"
      ),
      SIFRE_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_553038_value", "value", "", "", "")
        ),
        "string"
      ),
      TIP_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      Kullanici_Kodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_397591_value", "value", "", "", "")),
        "string"
      ),
      CARI_ISIM_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      password_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_553038_value", "value", "", "", "")),
        "string"
      ),
      userName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_397591_value", "value", "", "", "")),
        "string"
      ),
      firstName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_161038_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_191287_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciKayit = result?.data.kullaniciKayit;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarComponent_191287_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarComponent_191287_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    await this.AyarlarPageInit();

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ayarlar_397591_value", null, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ayarlar_961747_value", null, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ayarlar_553038_value", null, null);
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f9d0c738_31aa_2e71_5828_f708242c536c_notify",
        this.defaultML,
        "Kayıt Tamamlandı."
      ),
      "success",
      "bottom-right",
      3,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_602606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ayarlar_397591_value",
      ReactSystemFunctions.value(this, "ayarlar_622869_value", ".carI_KODU"),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ayarlar_961747_value",
      ReactSystemFunctions.value(this, "ayarlar_622869_value", ".depO_KODU"),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ayarlar_553038_value",
      ReactSystemFunctions.value(this, "ayarlar_622869_value", ".sifre"),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_737536_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("397591");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_532136_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CARI_KODU_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ayarlar_622869_value", "carI_KODU"),
        "string"
      ),
      TIP_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      userName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ayarlar_622869_value", "carI_KODU"),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "8e1e1076_993c_da84_dfd9_457f2911935f_confirmation",
        this.defaultML,
        "Seçilen Kullanıcı Silinsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_532136_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Kullanici_Sil = result?.data.kullanici_Sil;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AyarlarComponent_532136_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarComponent_532136_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    await this.AyarlarPageInit();

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_557158_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Kullanici_Tipi_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_557158_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciListesi = result?.data.kullaniciListesi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarComponent_557158_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarComponent_557158_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    await this.AyarlarPageInit();

    stateVars.dataSource_622869 = this.state.KullaniciListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_206630_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_369893_value", "value", "", "", "")),
        "string"
      ),
      SubeKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_142568_value", "value", "", "", "")),
        "string"
      ),
      NetsisPasword_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_952832_value", "value", "", "", "")
        ),
        "string"
      ),
      NetsisUser_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_579518_value", "value", "", "", "")),
        "string"
      ),
      DBName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_161038_value", "value", "", "", "")),
        "string"
      ),
      DBUser_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_979676_value", "value", "", "", "")),
        "string"
      ),
      aktifToken_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      dbName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_161038_value", "value", "", "", "")),
        "string"
      ),
      netsisPass_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_952832_value", "value", "", "", "")),
        "string"
      ),
      netsisUser_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_579518_value", "value", "", "", "")),
        "string"
      ),
      noxUrl_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_369893_value", "value", "", "", "")),
        "string"
      ),
      noxUser_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_979676_value", "value", "", "", "")),
        "string"
      ),
      subeKodu_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "ayarlar_142568_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_206630_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TokenVer = result?.data.tokenVer;
    stateVars.ParametreAyarlari_N = result?.data.parametreAyarlari_N;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarComponent_206630_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarComponent_206630_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(this, null, "default", "bottom-right", 1, null, null, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarComponent_206630_onClick_if11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AyarlarComponent_206630_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ACIKLAMA_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.TokenVer),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_206630_onClick_if11_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TokenLogKayit = result?.data.tokenLogKayit;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AyarlarComponent_206630_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "322223d9_3246_5224_5ade_c7c6952b6fd4_notify",
        this.defaultML,
        "Bağlantı Başarılı"
      ),
      "success",
      "bottom-right",
      3,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarComponent_206630_onClick_else11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AyarlarComponent_206630_onClick_else11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aktifToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.TokenVer),
        "string"
      ),
      dbName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_161038_value", "value", "", "", "")
        ),
        "string"
      ),
      netsisPass_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_952832_value", "value", "", "", "")
        ),
        "string"
      ),
      netsisUser_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_579518_value", "value", "", "", "")
        ),
        "string"
      ),
      noxUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_369893_value", "value", "", "", "")
        ),
        "string"
      ),
      noxUser_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ayarlar_979676_value", "value", "", "", "")
        ),
        "string"
      ),
      subeKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_142568_value", "value", "", "", "")),
        "number"
      ),
      ACIKLAMA_1: ReactSystemFunctions.convertToTypeByName("Manuel Token İşlemi Gerçekleşti", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_206630_onClick_else11_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ParametreAyarlari_N = result?.data.parametreAyarlari_N;
    stateVars.TokenLogKayit = result?.data.tokenLogKayit;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AyarlarComponent_206630_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.contains(ReactSystemFunctions.toString(this, this.state.TokenVer), "Bağlantı Başarısız")) {
      isErrorOccurred = await this.AyarlarComponent_206630_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.AyarlarComponent_206630_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_458577_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ACIKLAMA_3: ReactSystemFunctions.convertToTypeByName("Manuel Senkronize Et Butonuna Tıklandı.", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_458577_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AktifSirket_N = result?.data.aktifSirket_N;
    formVars.ayarlar_161038_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].dbName
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].dbName
        : null
    );
    formVars.ayarlar_142568_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].subeKodu
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].subeKodu
        : null
    );
    formVars.ayarlar_579518_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].netsisUser
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].netsisUser
        : null
    );
    formVars.ayarlar_979676_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].noxUser
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].noxUser
        : null
    );
    formVars.ayarlar_369893_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].noxUrl
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].noxUrl
        : null
    );
    stateVars.TokenVer = result?.data.tokenVer;
    stateVars.ParametreAyarlari_N = result?.data.parametreAyarlari_N;
    stateVars.TokenLogKayit = result?.data.tokenLogKayit;
    stateVars.SunucuFasonTableTempDelete = result?.data.sunucuFasonTableTempDelete;
    stateVars.FasonVeriAktarMergeInto = result?.data.fasonVeriAktarMergeInto;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AyarlarComponent_458577_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarComponent_458577_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.ayarlar_161038_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].dbName
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].dbName
        : null
    );

    formVars.ayarlar_142568_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].subeKodu
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].subeKodu
        : null
    );

    formVars.ayarlar_579518_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].netsisUser
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].netsisUser
        : null
    );

    formVars.ayarlar_952832_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].netsisPass
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].netsisPass
        : null
    );

    formVars.ayarlar_979676_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].noxUser
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].noxUser
        : null
    );

    formVars.ayarlar_369893_value = ReactSystemFunctions.toString(
      this,
      stateVars.AktifSirket_N?.length > 0
        ? stateVars.AktifSirket_N[0].noxUrl
        : this.state.AktifSirket_N?.length > 0
        ? this.state.AktifSirket_N[0].noxUrl
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AyarlarPageInit();
    }
  }
}
